<template>
<div>


  <div v-if="notshow2" class="div-unit">
    <div class="div-title">
      <div class="div-text">
        {{ unitTitle }}
        <span v-html="customHTML" class="div-unit-custom"></span>
        {{ unitTitle2 }}
      </div>
      <div
        @click="show1 = !show1"
        class="div-tool"
        title="Click to show or hide"
        style="cursor: pointer"
      >
        <i v-show="!show1" class="fa fa-angle-double-down"></i>
        <i v-show="show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="!show1">
        <div class="div-content" style="padding: 20px">
          <slot></slot>
        </div>
      </div>
    </el-collapse-transition>
  </div>

  <div v-else class="div-unit">
    <div class="div-title">
      <div class="div-text">
        {{ unitTitle }}
        <span v-html="customHTML" class="div-unit-custom"></span>
        {{ unitTitle2 }}
      </div>
      <div
        @click="show1 = !show1"
        class="div-tool"
        title="Click to show or hide"
        style="cursor: pointer"
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1">
        <div class="div-content" style="padding: 20px">
          <slot></slot>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</div>
</template>
  
  <script>
export default {
  name: "DivUnit",
  data() {
    return {
      show1: true,
    };
  },
  props: {
    unitTitle: String,
    unitTitle2: String,
    customHTML: String,
    notshow2: Boolean,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.div-unit {
  border: 3px solid #5f75c2;
  margin: 0px 0 15px 0;
  padding: 0 1%;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 5px #e4e8f5;
}
.div-title {
  color: #5f75c2;
  padding: 8px;
  line-height: 1.42857;
  text-align: left;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
}
.div-text {
  display: inline-block;
}
.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}
.div-content {
  padding: 25px 10px !important;
  border-top: 3px solid #5f75c2;
}
</style>
  