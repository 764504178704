import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/graph/wordcloud',
  //   name: 'wordcloud',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/WordCloud.vue')
  // },
  // {
  //   path: '/graph/sunburst',
  //   name: 'sunburst',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SunBurst.vue')
  // },
  {
    path: '/disease-class-analysis',
    name: 'DiseaseClassAnalysis',
    component: () => import(/* webpackChunkName: "about" */ '../views/DiseaseClassAnalysis.vue'),
    meta: {
      title: 'Customized assessment of ClinSRs for clinical trial drugs based on disease class | ClinSR'
    }
  },
  {
    path: '/drug-modality-analysis',
    name: 'DrugModalityAnalysis',
    component: () => import(/* webpackChunkName: "about" */ '../views/DrugModalityAnalysis.vue'),
    meta: {
      title: 'Customized assessment of ClinSRs for clinical trial drugs based on drug modality | ClinSR'
    }
  },
  {
    path: '/user-defined-input',
    name: 'user-defined-input',
    component: () => import(/* webpackChunkName: "about" */ '../views/DrugTimline.vue'),
    meta: {
      title: 'Advanced analysis of ClinSR for drug group of interest | ClinSR'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact US | ClinSR'
    }
  },


  {
    path: '/search-disease-page',
    name: 'search-disease-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDiseasePage.vue'),
    meta: {
      title: 'Search for Disease | ClinSR'
    }
  },
  {
    path: '/search-drug-page',
    name: 'search-drug-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDrugPage.vue'),
    meta: {
      title: 'Search for Drug | ClinSR'
    }
  },
  {
    path: '/data-drug',
    name: 'data-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDrug.vue'),
    meta: {
      title: 'Drug Information | ClinSR'
    }
  },
  {
    path: '/search-drug',
    name: 'search-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDrug.vue'),
    meta: {
      title: 'Search for Drug | ClinSR'
    }
  },
  {
    path: '/data-disease',
    name: 'data-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDisease.vue'),
    meta: {
      title: 'Disease Information | ClinSR'
    }
  },
  {
    path: '/search-disease',
    name: 'search-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDisease.vue'),
    meta: {
      title: 'Search for Disease | ClinSR'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "about" */ '../views/StatisticsPage.vue'),
    meta: {
      title: 'Statistics | ClinSR'
    }
  },
  {
    path: '/update-data-SR',
    name: 'update-data-SR',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateDate.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
