<template>
  <div class="main-width">
    <!-- <div class="home-title">
      <div style="margin: auto 0">
        <img src="/pic/title.png" style="width: 90%; margin: 0 auto" />
      </div>
    </div> -->
    <div class="interpretation home-interpretation">
      <div style="margin-bottom: 20px">
        This website describes the change of
        <span style="font-style: italic">clinical trial success rates</span>
        (ClinSR) of drugs over time. Readers can select a
        <span
          style="font-style: italic; font-weight: bold"
          class="orange-link"
          @click="dialogSelectByDiseaseClass = true"
          >Disease Class</span
        >
        or a
        <span
          style="font-style: italic; font-weight: bold"
          class="orange-link"
          @click="dialogSelectByDrugModality = true"
          >Drug Modality</span
        >
        to visualize the corresponding ClinSR since year 2000. Various databases
        (<span
          style="font-style: italic"
          class="blue-link"
          @click="dialogTip = true"
          >show details</span
        >) were included to in the analysis, and this website will be
        continuously updated to illustrate the temporal ClinSR for all drugs of
        the 21st century.
      </div>
      <div style="position: relative">
        <div style="display: flex">
          <div
            style="
              color: #526170;
              flex-grow: 1;
              display: flex;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              align-items: center;
            "
          >
            <div>Click to Select</div>
            <i
              class="fa fa-angle-double-right"
              aria-hidden="true"
              style="margin: 0 15px"
            ></i>
          </div>
          <div
            class="new-font new-font-select"
            style="font-weight: bold; display: flex; padding: 0 10px"
            @click="dialogSelect = true"
          >
            <div style="white-space: nowrap">
              {{ dis_value_show }}
            </div>
            <!-- <i class="fa fa-hand-o-up" aria-hidden="true"></i> -->
            <div>
              <i
                class="fa fa-angle-down"
                aria-hidden="true"
                style="margin: 0 5px"
              ></i>
            </div>
          </div>
          <div
            style="
              color: #cccccc00;
              flex-grow: 1;
              display: flex;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              align-items: center;
            "
          >
            <div>Click to Select</div>
            <i
              class="fa fa-angle-double-right"
              aria-hidden="true"
              style="margin: 0 15px"
            ></i>
          </div>
        </div>
      </div>
      <div style="">
        <el-dialog
          title="Select a Disease Class or Drug Modality"
          :visible.sync="dialogSelect"
          class="dialog-select"
        >
          <el-cascader-panel
            v-model="dis_value"
            :options="dis_options"
            style="font-size: 20px; height: 100%"
            popper-class="train-tree-cascader"
          ></el-cascader-panel>
        </el-dialog>
        <el-dialog
          title="Select a Disease Class"
          :visible.sync="dialogSelectByDiseaseClass"
          class="dialog-select"
        >
          <el-cascader-panel
            v-model="dis_value"
            :options="[dis_options[0]]"
            style="font-size: 20px; height: 100%"
            popper-class="train-tree-cascader"
          ></el-cascader-panel>
        </el-dialog>
        <el-dialog
          title="Select a Drug Modality"
          :visible.sync="dialogSelectByDrugModality"
          class="dialog-select"
        >
          <el-cascader-panel
            v-model="dis_value"
            :options="[dis_options[1]]"
            style="font-size: 20px; height: 100%"
            popper-class="train-tree-cascader"
          ></el-cascader-panel>
        </el-dialog>
      </div>
      <div style="">
        <el-dialog
          title="Databases Included in The Analysis"
          :visible.sync="dialogTip"
        >
          <div
            style="font-size: 20px; border: 1px solid #e4e7ed; padding: 16px"
          >
            Drug approval data are gathered from the
            <a
              href="https://www.fda.gov/drugs"
              target="_blank"
              class="blue-link"
              >U.S. FDA</a
            >
            (<a
              href="https://www.fda.gov/drugs"
              target="_blank"
              class="darkblue-link"
              >https://www.fda.gov/drugs</a
            >)<br />
            Clinical trial information of drugs is collected from the
            <a
              href="https://clinicaltrials.gov"
              target="_blank"
              class="blue-link"
              >ClinicalTrials.gov</a
            >
            (<a
              href="https://clinicaltrials.gov"
              target="_blank"
              class="darkblue-link"
              >https://clinicaltrials.gov</a
            >)<br />
            Drug data are collected from
            <a href="https://go.drugbank.com/" target="_blank" class="blue-link"
              >DrugBank</a
            >
            (<a
              href="https://scholar.google.com/scholar?cluster=444449718634568081"
              target="_blank"
              class="darkblue-link"
              ><i>Nucleic Acids Res</i>. 2018</a
            >) and
            <a
              href="https://db.idrblab.net/ttd/"
              target="_blank"
              class="blue-link"
              >TTD</a
            >
            (<a
              href="https://scholar.google.com/scholar?cluster=2447918495040264217"
              target="_blank"
              class="darkblue-link"
              ><i>Nucleic Acids Res</i>. 2020</a
            >)<br />
            Disease classification is standardized based on the
            <a href="https://icd.who.int/" target="_blank" class="blue-link"
              >WHO ICD-11</a
            >
            (<a
              href="https://doi.org/10.1016/S0140-6736(19)31205-X"
              target="_blank"
              class="darkblue-link"
              ><i>Lancet</i>, 2019</a
            >)
          </div>
        </el-dialog>
      </div>
    </div>
    <div-unit
      unitTitle="Dynamic clinical trial success rate for"
      :customHTML="dis_value_show"
      v-loading="checkRateTrendData"
    >
      <div class="big-plot" style="height: 750px; text-align: center">
        <div
          style="
            display: inline-flex;
            background: #f5f7fa;
            border-radius: 14px;
            margin-bottom: 20px;
          "
        >
          <div
            @click="calMethod = 'CTPs'"
            :class="[
              'calMethod',
              calMethod == 'CTPs'
                ? 'calMethod-active-CTPs'
                : 'calMethod-deactive',
            ]"
          >
            <div class="tag-title">Clinical Trial Pipelines (CTPs)</div>
          </div>
          <div
            @click="calMethod = 'MEs'"
            :class="[
              'calMethod',
              calMethod == 'MEs'
                ? 'calMethod-active-MEs'
                : 'calMethod-deactive',
            ]"
          >
            <div class="tag-title">Molecular Entities (MEs)</div>
          </div>
          <!-- <div
            @click="calMethod = 'Industry'"
            :class="[
              'calMethod',
              calMethod == 'Industry'
                ? 'calMethod-active-Industry'
                : 'calMethod-deactive',
            ]"
          >
            <div class="tag-title">Industry-sponsored</div>
          </div> -->
        </div>

        <div id="rate-trend" style="height: 700px"></div>
      </div>
    </div-unit>
    <div-unit
      unitTitle="Dynamic change in research focus of drug modalities for"
      :customHTML="dis_value_show"
      v-loading="checkDrugTypeData"
      v-show="displayDrugTypeData"
    >
      <div id="drug-type-byDrug" class="half-big-plot"></div>
      <div id="drug-type-byNCT" class="half-big-plot"></div>
    </div-unit>
    <div-unit
      unitTitle="Comparison of Clinical trial success rate of disease classes in each time-window"
    >
      <div class="plot-row">
        <div class="big-plot" style="height: 750px; text-align: center">
          <div
            style="
              display: inline-flex;
              background: #f5f7fa;
              border-radius: 14px;
              margin-bottom: 20px;
            "
          >
            <div
              @click="calMethod = 'CTPs'"
              :class="[
                'calMethod',
                calMethod == 'CTPs'
                  ? 'calMethod-active-CTPs'
                  : 'calMethod-deactive',
              ]"
            >
              <div class="tag-title">Clinical Trial Pipelines (CTPs)</div>
            </div>
            <div
              @click="calMethod = 'MEs'"
              :class="[
                'calMethod',
                calMethod == 'MEs'
                  ? 'calMethod-active-MEs'
                  : 'calMethod-deactive',
              ]"
            >
              <div class="tag-title">Molecular Entities (MEs)</div>
            </div>
            <!-- <div
              @click="calMethod = 'Industry'"
              :class="[
                'calMethod',
                calMethod == 'Industry'
                  ? 'calMethod-active-Industry'
                  : 'calMethod-deactive',
              ]"
            >
              <div class="tag-title">Industry-sponsored</div>
            </div> -->
          </div>
          <div id="change-time" style="height: 700px"></div>
        </div>
      </div>
    </div-unit>

    <!-- <div-unit
      unitTitle="Temporal Phase Duration for"
      :customHTML="dis_value_show"
      v-loading="checkPhaseDurationData"
    >
      <div id="phase-duration" class="big-plot"></div>
    </div-unit> -->
    <div-unit
      unitTitle="Statistics for"
      :customHTML="dis_value_show"
      v-loading="checkDrugStatistics1Data"
    >
      <div class="plot-row">
        <div id="drug-statistics1" class="small-plot"></div>
        <div id="drug-statistics2" class="small-plot"></div>
      </div>

      <div
        class="plot-row"
        style="box-shadow: 1px 1px 4px 5px #e4e8f5; border-radius: 6px"
      >
        <div
          id="drug-statistics3"
          class="small-plot"
          style="height: 568px; box-shadow: 0 0 0px 0px #e4e8f5"
        ></div>
        <div
          id="drug-statistics4"
          class="small-plot"
          style="height: 568px; box-shadow: 0 0 0px 0px #e4e8f5"
        ></div>
      </div>
    </div-unit>
  </div>
</template>

<script>
import "@/assets/fonts/font.css";
import DivUnit from "@/components/DivUnit.vue";
import * as echarts from "echarts";
import api from "../api/index";
// import pathUrl from "../../api/path";
export default {
  name: "HomeView",
  data() {
    return {
      calMethod: "CTPs",
      show1: true,
      show2: true,
      dialogSelect: false,
      dialogSelectByDiseaseClass: false,
      dialogSelectByDrugModality: false,
      dialogTip: false,
      dis_options: [],
      dis_value: ["Disease Class", "All diseases"],
      dis_value_show: "All diseases",
      plotRateTrendData: "",
      checkRateTrendData: true,
      // plotPhaseDurationData: "",
      checkPhaseDurationData: true,
      plotDrugStatistics1Data: "",
      plotDrugStatistics2Data: "",
      plotDrugStatistics3Data: "",
      plotDrugStatistics4Data: "",
      checkDrugStatistics1Data: true,
      plotDrugTypeData: "",
      checkDrugTypeData: true,

      displayDrugTypeData: true,

      bgColors: ["rgb(252, 250, 226)", "rgb(255, 255, 255)"],
    };
  },
  components: {
    DivUnit,
  },
  watch: {
    dis_value: {
      handler: function (o) {
        console.log(o);
        this.dis_value_show = this.dis_value[1].split("[")[0];
        if (o[0] == "Disease Class" && o[1] == "Others [ICD11:M|N|A|J|K|L]") {
          this.dis_value_show = "Other disease classes";
        } else if (o[1] == "Small molecule") {
          this.dis_value_show = "Small molecular drug";
        } else if (o[1] == "Protein/Peptide") {
          this.dis_value_show = "Protein & peptide drug";
        } else if (o[1] == "Antibody/Antibody-drug conjugate") {
          this.dis_value_show = "Antibody-related drug";
        } else if (o[1] == "Others") {
          this.dis_value_show = "Other drug";
        }

        // o[1] = o[1].replace("All drug modality", "All diseases");
        this.dialogSelect = false;
        this.dialogSelectByDiseaseClass = false;
        this.dialogSelectByDrugModality = false;

        this.displayDrugTypeData = o[0] == "Disease Class" ? true : false;
        // console.log(this.displayDrugTypeData);

        this.plotRateTrend(this.plotRateTrendData[o[1]], this.bgColors);
        // this.plotPhaseDuration(this.plotPhaseDurationData[o[1]]);
        this.plotDrugStatistics12(
          this.plotDrugStatistics1Data[o[1]],
          "drug-statistics1",
          "Cumulative number of trials over time"
        );
        this.plotDrugStatistics12(
          this.plotDrugStatistics2Data[o[1]],
          "drug-statistics2",
          "Cumulative number of trials for each time window"
        );
        this.plotDrugStatistics3(this.plotDrugStatistics3Data[o[1]]);
        this.plotDrugStatistics4(this.plotDrugStatistics4Data[o[1]]);
        if (o[0] == "Disease Class") {
          this.plotDrugType(
            this.plotDrugTypeData.byDrug[o[1]],
            "drug-type-byDrug"
          );
          this.plotDrugType(
            this.plotDrugTypeData.byNCT[o[1]],
            "drug-type-byNCT"
          );
        }
      },
      deep: true,
    },
    calMethod: {
      handler: function () {
        if (this.calMethod == "CTPs") {
          this.bgColors = ["rgb(252, 250, 226)", "rgb(255, 255, 255)"];
        } else if (this.calMethod == "MEs") {
          this.bgColors = ["rgb(215, 231, 243)", "rgb(255, 255, 255)"];
        } else if (this.calMethod == "Industry") {
          this.bgColors = ["rgb(216, 238, 208)", "rgb(255, 255, 255)"];
        }
        api.getSuccessRateTrend(this.calMethod).then((res) => {
          this.plotRateTrendData = res.data;
          this.checkRateTrendData = false;
          // console.log(this.dis_value][1]);
          this.plotRateTrend(
            this.plotRateTrendData[
              this.dis_value[1].replace("All drug modality", "All diseases")
            ],
            this.bgColors
          );
        });

        api.getStatByTime(this.calMethod).then((res) => {
          // this.plotStatByTimeData = res.data.result;
          // console.log(this.plotStatByTimeData);
          this.plotStatByTime(res.data.result);
        });
      },
      deep: true,
    },
  },
  methods: {
    plotRateTrend(graph, bgColors) {
      let dom = document.getElementById("rate-trend");
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });
      // let point_icon =
      //   "path://M1240 1994 c-14 -3 -61 -16 -106 -29 -142 -43 -189 -82 -625 -521       -387 -390 -397 -401 -438 -484 -55 -113 -71 -184 -71 -305 0 -255 136 -475       364 -589 98 -49 172 -66 291 -66 153 0 260 32 375 111 30 20 233 217 451 436       325 326 403 410 432 463 107 196 113 417 17 615 -108 224 -319 361 -570 370       -52 2 -106 1 -120 -1z m264 -167 c172 -63 300 -205 341 -379 19 -83 19 -196       -1 -266 -36 -124 -67 -167 -255 -357 l-179 -180 -382 383 -383 383 175 172       c188 186 253 230 376 262 86 21 220 13 308 -18z";
      // let a= graph_data;
      // myChart.showLoading();
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        // backgroundColor: {
        //   type: "linear",
        //   x: 0,
        //   x2: 1,
        //   y: 0,
        //   y2: 0,
        //   colorStops: [
        //     { offset: 0, color: "rgb(255, 255, 255)" },
        //     { offset: 1, color: "rgb(236, 236, 236)" },
        //   ],
        // },
        legend: [
          {
            data: ["P1SR", "P2SR", "P3SR", "OSR"],
            itemGap: 50,
            // icon: point_icon,
            // selectedMode: false, //取消图例上的点击事件
            textStyle: {
              fontSize: "16",
            },
          },
        ],
        grid: {
          top: "4%",
          left: "3%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            saveAsImage: {},
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            data: graph.timeline,
            axisTick: {
              alignWithLabel: false,
              show: true,
            },
            axisLine: {
              show: true, // 不显示坐标轴线
              // symbol: 'arrow',
              lineStyle: {
                // type: 'dashed',
                width: 2,
                color: "#000",
              },
            },
            axisLabel: {
              interval: 0,
              rotate: 40,
              textStyle: {
                color: "#000",
                fontSize: "18px",
              },
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],

        yAxis: [
          {
            name: "Phase Success Rate (PSR)",
            // nameRotate: 90,
            nameLocation: "center",
            nameGap: 55,
            nameTextStyle: {
              color: "#000",
              fontSize: "18px",
              // padding: [80, 40, 0, -20],
            },
            //min: 0,
            //max: 100,
            axisLine: {
              show: true, // 不显示坐标轴线
              // symbol: 'arrow',
              lineStyle: {
                // type: 'dashed',
                width: 2,
                color: "#000",
              },
            },
            axisTick: {
              show: true, // 不显示坐标轴刻度线
            },
            axisLabel: {
              formatter: (params) => {
                return `${params}%`;
              },
              color: "#000",
              textStyle: {
                fontSize: "18px",
              },
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
          {
            name: "Overall Success Rate (OSR)",
            nameRotate: -90,
            nameLocation: "middle",
            nameGap: 55,
            nameTextStyle: {
              color: "#000",
              fontSize: "18px",
              // padding: [400, 40, 0, -20],
            },
            //min: 0,
            //max: 100,
            axisLine: {
              show: true, // 不显示坐标轴线
              // symbol: 'arrow',
              lineStyle: {
                // type: 'dashed',
                width: 2,
                color: "#000",
              },
            },
            axisTick: {
              show: true, // 不显示坐标轴刻度线
            },
            axisLabel: {
              formatter: (params) => {
                return `${params}%`;
              },
              color: "#000",
              textStyle: {
                fontSize: "18px",
              },
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        tooltip: {
          //alwaysShowContent: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow", //默认为line，line直线，cross十字准星，shadow阴影
            // shadowStyle: { color: "#e7864038" },
          },
          enterable: true,
          confine: true,

          formatter: function (params) {
            var htmlStr = "";
            for (var i = 0; i < params.length - 1; i++) {
              var param = params[i];
              var xName = param.name; //x轴的名称
              var seriesName = param.seriesName; //图例名称
              var value = param.value; //y轴值
              var color =
                param.color instanceof Object
                  ? param.color.colorStops[0].color
                  : param.color; //图例颜色
              if (i === 0) {
                htmlStr +=
                  '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">' +
                  xName +
                  "</div>"; //x轴的名称
              }
              htmlStr +=
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
                color +
                ';"></span>' +
                '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
                seriesName +
                "</span>" +
                '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
                (parseFloat(value)+0.002).toFixed(1) +
                "%" +
                "</span>" +
                '<div style="clear:both"></div>' +
                "</div>" +
                '<div style="clear:both"></div>' +
                "</div>";
            }

            return htmlStr;
          },
        },
        series: [
          {
            name: "P1SR",
            z: 2,
            type: "bar",
            data: graph.s_rate1,
            //barWidth: 30,
            // barGap: "-50%",
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(101, 162, 210)" },
                  { offset: 0.3, color: "rgb(101, 162, 210)" },
                  { offset: 0.5, color: "rgb(162, 185, 226)" },
                  { offset: 0.75, color: "rgb(101, 162, 210)" },
                  { offset: 1, color: "rgb(101, 162, 210)" },
                ],
              },
            },
          },

          {
            name: "P2SR",
            z: 2,
            type: "bar",
            data: graph.s_rate2,
            //barWidth: 30,
            // barGap: "-50%",
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(235, 202, 83)" },
                  { offset: 0.3, color: "rgb(235, 202, 83)" },
                  { offset: 0.5, color: "rgb(255, 231, 163)" },
                  { offset: 0.75, color: "rgb(235, 202, 83)" },
                  { offset: 1, color: "rgb(235, 202, 83)" },
                ],
              },
            },
          },

          {
            name: "P3SR",
            z: 2,
            type: "bar",
            data: graph.s_rate3,
            //barWidth: 30,
            // barGap: "-50%",
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(231, 111, 108)" },
                  { offset: 0.3, color: "rgb(231, 111, 108)" },
                  { offset: 0.5, color: "rgb(240, 166, 162)" },
                  { offset: 0.75, color: "rgb(231, 111, 108)" },
                  { offset: 1, color: "rgb(231, 111, 108)" },
                ],
              },
            },
          },
          {
            name: "OSR",
            z: 3,
            type: "line",
            data: graph.LOA1,
            yAxisIndex: 1,
            symbol: "circle",
            symbolSize: [8, 8],
            animationDelay: 1000,
            animationEasing: "linear",
            animationDuration: 4000,
            itemStyle: {
              normal: {
                color: "rgb(0, 0, 0)", //改变折线点的颜色
                lineStyle: {
                  color: "rgb(0, 0, 0)", //改变折线颜色
                  width: 3,
                },
              },
            },
          },
          {
            name: "use4bg",
            z: 1,
            type: "line",
            data: graph.LOA1,
            yAxisIndex: 1,
            xAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "rgba(0, 0, 0,0)", //改变折线点的颜色
                lineStyle: {
                  color: "rgba(0, 0, 0,0)", //改变折线颜色
                },
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: bgColors[0] },
                  { offset: 1, color: bgColors[1] },
                ],
              },
            },

            // backgroundColor: {
            //   type: "linear",
            //   x: 0,
            //   x2: 1,
            //   y: 0,
            //   y2: 0,
            //   colorStops: [
            //     { offset: 0, color: "rgb(255, 255, 255)" },
            //     { offset: 1, color: "rgb(236, 236, 236)" },
            //   ],
            // },
          },
        ],
      };
      // option.yAxis[2].max=graph.line_LOA1.map((x) => x.value).max;
      option.xAxis[1] = {
        type: "category",
        // max: option.xAxis[0].data.length * 100,
        boundaryGap: false,
        axisPointer: {
          type: "none",
        },
        show: false,
      };
      option.yAxis[2] = {
        type: "value",
        // max: option.yAxis[1].data.length * 100,
        boundaryGap: false,
        axisPointer: {
          type: "none",
        },
        show: false,
      };
      var osrMax = 0;
      for (var i = 0; i < option.series[3].data.length; i++) {
        let tmp = option.series[3].data[i].value;
        if (tmp > osrMax) osrMax = tmp;
      }
      // console.log(osrMax);
      option.yAxis[1].max = Math.ceil(osrMax / 5) * 5;
      option.yAxis[2].max = Math.ceil(osrMax / 5) * 5;
      option.series[4].data = option.series[3].data.map((x, i) => [
        i,
        option.yAxis[2].max,
      ]);
      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    // eslint-disable-next-line vue/no-dupe-keys
    plotPhaseDuration(graph) {
      let dom = document.getElementById("phase-duration");
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });
      // let point_icon =
      //   "path://M302 211.334C302 189.61 319.61 172 341.334 172L684.666 172C706.39 172 724 189.61 724 211.334L724 368.666C724 390.39 706.39 408 684.666 408L341.334 408C319.61 408 302 390.39 302 368.666Z";
      var piePatternImg1 = new Image();
      piePatternImg1.src = "/pic/p1fail.png";

      var piePatternImg2 = new Image();
      piePatternImg2.src = "/pic/p2fail.png";

      var piePatternImg3 = new Image();
      piePatternImg3.src = "/pic/p3fail.png";

      // let a= graph_data;
      // myChart.showLoading();
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        legend: [
          {
            data: ["Phase 1 success", "Phase 2 success", "Phase 3 success"],
            textStyle: {
              fontSize: "16",
            },
          },
          {
            data: [
              "Phase 1 fail       ",
              "Phase 2 fail       ",
              "Phase 3 fail       ",
            ],
            top: 30,
            // icon: "/pic/bar.svg",
            textStyle: {
              fontSize: "16",
            },
          },
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: graph.timeline,
          boundaryGap: true,
          axisTick: {
            alignWithLabel: false,
            show: true,
          },
          axisLine: {
            show: true, // 不显示坐标轴线
            // symbol: 'arrow',
            lineStyle: {
              // type: 'dashed',
              width: 2,
              color: "#000",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#000",
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            show: false,
          },
        },

        yAxis: {
          type: "value",
          name: "Duration of Phase (month)",
          nameLocation: "center",
          nameGap: 55,
          nameTextStyle: {
            color: "#000",
            fontSize: "18px",
            // padding: [80, 40, 0, -20],
          },
          //min: 0,
          //max: 100,
          axisLine: {
            show: true, // 不显示坐标轴线
            // symbol: 'arrow',
            lineStyle: {
              // type: 'dashed',
              width: 2,
              color: "#000",
            },
          },
          axisTick: {
            show: true, // 不显示坐标轴刻度线
          },
          axisLabel: {
            color: "#000",
            textStyle: {
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            show: false,
          },
        },
        tooltip: {
          // alwaysShowContent: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow", //默认为line，line直线，cross十字准星，shadow阴影
          },
          enterable: true,
          confine: true,
          formatter: function (params) {
            var htmlStr = "";
            console.log(params);
            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name; //x轴的名称
              var seriesName = param.seriesName; //图例名称
              var value = param.value; //y轴值
              // var color = param.color; //图例颜色

              if (seriesName.includes("Phase 1 fail")) {
                var color = "rgba(101, 162, 210, .3)";
                // console.log(seriesName);
              } else if (seriesName.includes("Phase 2 fail")) {
                // eslint-disable-next-line no-redeclare
                var color = "rgba(235, 202, 83, .3)";
                // console.log(seriesName);
              } else if (seriesName.includes("Phase 3 fail")) {
                // eslint-disable-next-line no-redeclare
                var color = "rgba(231, 111, 108, .3)";
                // console.log(seriesName);
              } else {
                // eslint-disable-next-line no-redeclare
                var color = param.color.colorStops[0].color;

                // var color = param.color instanceof Object ? param.color.colorStops[0].color : param.color; //图例颜色
              }
              if (i === 0) {
                htmlStr +=
                  '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">Duration of Phase in ' +
                  xName +
                  "</div>"; //x轴的名称
              }
              //console.log(color["colorStops"][0].color);
              // color = color["colorStops"][0].color;
              htmlStr +=
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
                color +
                ';"></span>' +
                '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
                seriesName +
                "</span>" +
                '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
                (parseFloat(value)+0.002).toFixed(1) +
                " month</span>" +
                '<div style="clear:both"></div>' +
                "</div>" +
                '<div style="clear:both"></div>' +
                "</div>";
            }

            return htmlStr;
          },
        },
        series: [
          {
            name: "Phase 1 success",
            type: "bar",
            stack: "one",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.p1_p2_gap,
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(101, 162, 210)" },
                  { offset: 0.3, color: "rgb(101, 162, 210)" },
                  { offset: 0.5, color: "rgb(162, 185, 226)" },
                  { offset: 0.75, color: "rgb(101, 162, 210)" },
                  { offset: 1, color: "rgb(101, 162, 210)" },
                ],
              },
            },
          },
          {
            name: "Phase 2 success",
            type: "bar",
            stack: "one",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.p2_p3_gap,
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(235, 202, 83)" },
                  { offset: 0.3, color: "rgb(235, 202, 83)" },
                  { offset: 0.5, color: "rgb(255, 231, 163)" },
                  { offset: 0.75, color: "rgb(235, 202, 83)" },
                  { offset: 1, color: "rgb(235, 202, 83)" },
                ],
              },
            },
          },
          {
            name: "Phase 3 success",
            type: "bar",
            stack: "one",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.p3_p4_gap,
            itemStyle: {
              color: {
                type: "linear",
                x: 1,
                x2: 0,
                y: 0,
                y2: 0,
                colorStops: [
                  { offset: 0, color: "rgb(231, 111, 108)" },
                  { offset: 0.3, color: "rgb(231, 111, 108)" },
                  { offset: 0.5, color: "rgb(240, 166, 162)" },
                  { offset: 0.75, color: "rgb(231, 111, 108)" },
                  { offset: 1, color: "rgb(231, 111, 108)" },
                ],
              },
            },
          },
          {
            name: "Phase 1 fail       ",
            type: "bar",
            stack: "two",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.dis_p1_p2_gap,
            color: {
              image: piePatternImg1,
              repeat: "repeat",
            },
          },
          {
            name: "Phase 2 fail       ",
            type: "bar",
            stack: "two",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.dis_p2_p3_gap,
            itemStyle: {
              color: {
                image: piePatternImg2,
                repeat: "repeat",
              },
            },
          },
          {
            name: "Phase 3 fail       ",
            type: "bar",
            stack: "two",
            label: {
              show: true,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph.dis_p3_p4_gap,
            color: {
              image: piePatternImg3,
              repeat: "repeat",
            },
          },
          {
            name: "Duration of phase:",
            type: "line",
          },
        ],
      };
      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotDrugStatistics12(graph, divid, title_input) {
      let dom = document.getElementById(divid);
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });

      // myChart.showLoading();
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        title: {
          text: title_input,
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          top: "8%",
          data: ["Phase 1", "Phase 2", "Phase 3", "Total"],
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: graph.xaxis,
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
          },
        ],
        series: [
          {
            name: "Phase 1",
            type: "line",
            emphasis: {
              focus: "series",
            },
            data: graph.P1,
            itemStyle: {
              normal: {
                color: "rgb(101, 162, 210)", //改变折线点的颜色
                lineStyle: {
                  color: "rgb(101, 162, 210)", //改变折线颜色
                },
              },
            },
          },
          {
            name: "Phase 2",
            type: "line",
            emphasis: {
              focus: "series",
            },
            data: graph.P2,
            itemStyle: {
              normal: {
                color: "rgb(235, 202, 83)", //改变折线点的颜色
                lineStyle: {
                  color: "rgb(235, 202, 83)", //改变折线颜色
                },
              },
            },
          },
          {
            name: "Phase 3",
            type: "line",
            emphasis: {
              focus: "series",
            },
            data: graph.P3,
            itemStyle: {
              normal: {
                color: "rgb(231, 111, 108)", //改变折线点的颜色
                lineStyle: {
                  color: "rgb(231, 111, 108)", //改变折线颜色
                },
              },
            },
          },
          {
            name: "Total",
            type: "line",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                color: "rgb(0, 0, 0)", //改变折线点的颜色
                lineStyle: {
                  color: "rgb(0, 0, 0)", //改变折线颜色
                },
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgb(239, 239, 239)" },
                  { offset: 1, color: "rgb(255, 255, 255)" },
                ],
                global: false,
              },
            },
            data: graph.total,
          },
        ],
      };

      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotDrugStatistics3(graph) {
      let dom = document.getElementById("drug-statistics3");
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });

      // myChart.showLoading();
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        title: [
          {
            text: "Top 10 sub-diseases with the largest number of clinical trials",
            left: "center",
          },
        ],

        angleAxis: {
          max: Math.max.apply(null, graph["value"]) * 1.2,
          startAngle: 75,
        },
        radiusAxis: {
          type: "category",
          data: graph["xaxis"],
          axisLabel: { clickable: true, interval: 0 },
        },
        polar: {
          radius: [30, "80%"],
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{b}</b>",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        series: {
          type: "bar",
          data: graph["value"],
          coordinateSystem: "polar",
          itemStyle: {
            normal: {
              color: function (params) {
                var colorList = [
                  "#ffe2d7",
                  "#ffd6ca",
                  "#ffc7ba",
                  "#ffc2b5",
                  "#ffb4a6",
                  "#ffaa9c",
                  "#ffa899",
                  "#ff988a",
                  "#ff7167",
                  "#ff6b62",
                  "#fe4b4b",
                ];
                return colorList[params.dataIndex];
              },
            },
          },
        },
      };

      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotDrugStatistics4(graph) {
      let dom = document.getElementById("drug-statistics4");
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });

      // myChart.showLoading();
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        title: [
          {
            text: "Top 10 sub-diseases with the largest number of drugs",
            left: "center",
          },
        ],
        angleAxis: {
          type: "category",
          data: graph["xaxis"],
        },
        radiusAxis: {
          //max: Math.max.apply(null, graph["value"]) * 1.3,
        },
        polar: {
          radius: [30, "80%"],
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{b}</b>",
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            type: "bar",
            data: graph["value"],
            coordinateSystem: "polar",
            itemStyle: {
              normal: {
                color: function (params) {
                  var colorList = [
                    "#ffe2d7",
                    "#ffd6ca",
                    "#ffc7ba",
                    "#ffc2b5",
                    "#ffb4a6",
                    "#ffaa9c",
                    "#ffa899",
                    "#ff988a",
                    "#ff7167",
                    "#ff6b62",
                    "#fe4b4b",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotDrugType(graph, divid) {
      let dom = document.getElementById(divid);

      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });

      let titleDist = {
        "drug-type-byDrug": "Drugs",
        "drug-type-byNCT": "CTPs",
      };
      let colorDist = {
        "drug-type-byNCT": ["#448ae2", "#90cdec", "#a650bf", "#858bc5"],
        "drug-type-byDrug": ["#13acd8", "#6ac496", "#126271", "#d1c211"],
      };
      let option = {
        textStyle: {
          fontFamily: "Harding",
        },
        title: {
          text: `Change in the Focus of Drug Modalities Measured by ${titleDist[divid]}`,
          left: "center",
          // textStyle: {
          //   fontFamily: "Harding",
          // },
        },
        tooltip: {
          //alwaysShowContent: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow", //默认为line，line直线，cross十字准星，shadow阴影
          },
          enterable: true,
          confine: true,
          //formatter: '{a}: {b}<br/>Ratio: {d}%'
          formatter: function (params) {
            var htmlStr = "";

            for (var i = 0; i < params.length; i++) {
              var param = params[i];
              var xName = param.name; //x轴的名称
              var seriesName = param.seriesName; //图例名称
              var value = param.value; //y轴值
              var color =
                param.color instanceof Object
                  ? param.color.colorStops[0].color
                  : param.color; //图例颜色

              if (i === 0) {
                htmlStr +=
                  '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">' +
                  xName +
                  "</div>"; //x轴的名称
              }
              htmlStr +=
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<div style="margin: 6px 0 0;line-height:1;">' +
                '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
                color +
                ';"></span>' +
                '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
                seriesName +
                "</span>" +
                '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
                (parseFloat(value)+0.002).toFixed(1) +
                "%" +
                "</span>" +
                '<div style="clear:both"></div>' +
                "</div>" +
                '<div style="clear:both"></div>' +
                "</div>";
            }
            return htmlStr;
          },
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   // yAxisIndex: "none",
            // },
            saveAsImage: {},
          },
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "3%",
          bottom: "1%",
          containLabel: true,
        },
        legend: {
          itemGap: 50,
          top: "9%",
          // icon: point_icon,
          selectedMode: false, //取消图例上的点击事件
          textStyle: {
            fontSize: "16",
          },
          data: [
            "Small Molecular Drug",
            "Protein & Peptide Drug",
            "Antibody-related Drug",
            "Other Drug",
          ],
        },

        xAxis: {
          type: "category",
          data: graph["timeline"],
          boundaryGap: true,
          axisTick: {
            alignWithLabel: false,
            show: true,
          },
          axisLine: {
            show: true, // 不显示坐标轴线
            lineStyle: {
              width: 2,
              color: "#000",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#000",
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            show: false,
          },
        },
        yAxis: {
          type: "value",
          min: 20,
          max: 100,
          nameGap: 55,
          name: "Percentage (%)",
          nameLocation: "center",
          nameTextStyle: {
            color: "#000",
            fontSize: "18px",
            // padding: [80, 40, 0, -20],
          },
          axisLine: {
            show: true, // 不显示坐标轴线
            lineStyle: {
              width: 2,
              color: "#000",
            },
          },
          axisTick: {
            show: true, // 不显示坐标轴刻度线
          },
          axisLabel: {
            formatter: (params) => {
              return `${params}%`;
            },
            color: "#000",
            textStyle: {
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            show: false,
          },
        },
        series: [
          {
            name: "Small Molecular Drug",
            type: "bar",
            barWidth: 35,
            stack: "total",
            label: {
              show: false,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph["Small molecule"],

            itemStyle: {
              borderRadius: 5,

              borderColor: "white",
              borderWidth: 0.5,
              color: colorDist[divid][0],
            },
          },
          {
            name: "Protein & Peptide Drug",
            type: "bar",
            barWidth: 35,
            stack: "total",
            label: {
              show: false,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph["Protein/Peptide"],
            itemStyle: {
              borderRadius: 5,

              borderColor: "white",
              borderWidth: 0.5,

              color: colorDist[divid][1],
            },
          },
          {
            name: "Antibody-related Drug",
            type: "bar",
            barWidth: 35,
            stack: "total",
            label: {
              show: false,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph["Antibody/Antibody-drug conjugate"],
            itemStyle: {
              borderRadius: 5,

              borderColor: "white",
              borderWidth: 0.5,
              color: colorDist[divid][2],
            },
          },

          {
            name: "Other Drug",
            type: "bar",
            barWidth: 35,
            stack: "total",
            label: {
              show: false,
              color: "black",
            },
            emphasis: {
              focus: "none",
            },
            data: graph["Others"],
            itemStyle: {
              borderRadius: 5,

              borderColor: "white",
              borderWidth: 0.5,
              color: colorDist[divid][3],
            },
          },
        ],
      };

      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotStatByTime(graph) {
      let dom = document.getElementById("change-time");
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });
      // myChart.showLoading();
      var point_icon =
        "path://M-244.396,44.399c0,0,0.47-2.931-2.427-6.512c2.819-8.221,3.21-15.709,3.21-15.709s5.795,1.383,5.795,7.325C-237.818,39.679-244.396,44.399-244.396,44.399z M-260.371,40.827c0,0-3.881-12.946-3.881-18.319c0-2.416,0.262-4.566,0.669-6.517h17.684c0.411,1.952,0.675,4.104,0.675,6.519c0,5.291-3.87,18.317-3.87,18.317H-260.371z M-254.745,18.951c-1.99,0-3.603,1.676-3.603,3.744c0,2.068,1.612,3.744,3.603,3.744c1.988,0,3.602-1.676,3.602-3.744S-252.757,18.951-254.745,18.951z M-255.521,2.228v-5.098h1.402v4.969c1.603,1.213,5.941,5.069,7.901,12.5h-17.05C-261.373,7.373-257.245,3.558-255.521,2.228zM-265.07,44.399c0,0-6.577-4.721-6.577-14.896c0-5.942,5.794-7.325,5.794-7.325s0.393,7.488,3.211,15.708C-265.539,41.469-265.07,44.399-265.07,44.399z M-252.36,45.15l-1.176-1.22L-254.789,48l-1.487-4.069l-1.019,2.116l-1.488-3.826h8.067L-252.36,45.15z";

      let option = {
        baseOption: {
          textStyle: {
            fontFamily: "Harding",
          },
          animationDurationUpdate: 2000,
          animationDuration: 1000,
          timeline: {
            axisType: "category",
            left: 4,
            right: 4,
            // realtime: false,
            // loop: false,
            autoPlay: true,
            // currentIndex: 2,
            playInterval: 4000,
            controlStyle: {
              position: "left",
            },
            label: {
              show: true,
              interval: 0,
              rotate: 10,
              formatter: null,
              textStyle: { color: "#333" },
            },
            data: graph.timeline,
          },
          title: {
            subtext: "",
          },

          tooltip: {
            //alwaysShowContent: true,
            trigger: "axis",
            axisPointer: {
              type: "shadow", //默认为line，line直线，cross十字准星，shadow阴影
              // shadowStyle: { color: "#e7864038" },
            },
            enterable: true,
            confine: true,

            formatter: function (params) {
              var htmlStr = "";
              // console.log(params);
              for (var i = 0; i < params.length; i++) {
                var param = params[i];
                var xName = param.name; //x轴的名称
                var seriesName = param.seriesName; //图例名称
                var value = param.value; //y轴值
                var color =
                  param.color instanceof Object
                    ? param.color.colorStops[0].color
                    : param.color; //图例颜色
                if (i === 0) {
                  htmlStr +=
                    '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">' +
                    xName +
                    "</div>"; //x轴的名称
                }
                htmlStr +=
                  '<div style="margin: 6px 0 0;line-height:1;">' +
                  '<div style="margin: 6px 0 0;line-height:1;">' +
                  '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
                  color +
                  ';"></span>' +
                  '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
                  seriesName +
                  "</span>" +
                  '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
                  (parseFloat(value)+0.002).toFixed(1) +
                  "%" +
                  "</span>" +
                  '<div style="clear:both"></div>' +
                  "</div>" +
                  '<div style="clear:both"></div>' +
                  "</div>";
              }

              return htmlStr;
            },
          },
          legend: [
            {
              top: 40,
              data: ["P1SR", "P2SR", "P3SR", "OSR"],
              itemGap: 50,
              // icon: point_icon,
              // selectedMode: false, //取消图例上的点击事件
              textStyle: {
                fontSize: "16",
              },
            },
          ],
          calculable: true,
          grid: {
            top: 90,
            bottom: 100,
            // tooltip: {
            //   trigger: "axis",
            //   axisPointer: {
            //     type: "shadow",
            //     label: {
            //       show: true,
            //       formatter: function (params) {
            //         return params.value.replace("\n", "");
            //       },
            //     },
            //   },
            // },
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              data: graph.DiseaseClass,
              axisTick: {
                alignWithLabel: false,
                show: true,
              },
              axisLine: {
                show: true, // 不显示坐标轴线
                // symbol: 'arrow',
                lineStyle: {
                  // type: 'dashed',
                  width: 2,
                  color: "#000",
                },
              },
              axisLabel: {
                interval: 0,
                rotate: 40,
                textStyle: {
                  color: "#000",
                  fontSize: "14px",
                },
              },
              splitLine: {
                //网格线
                show: false,
              },
            },
          ],
          yAxis: [
            {
              name: "Phase Success Rate (PSR)",
              // nameRotate: 90,
              nameLocation: "center",
              nameGap: 55,
              nameTextStyle: {
                color: "#000",
                fontSize: "18px",
                // padding: [80, 40, 0, -20],
              },
              //min: 0,
              //max: 100,
              axisLine: {
                show: true, // 不显示坐标轴线
                // symbol: 'arrow',
                lineStyle: {
                  // type: 'dashed',
                  width: 2,
                  color: "#000",
                },
              },
              axisTick: {
                show: true, // 不显示坐标轴刻度线
              },
              axisLabel: {
                formatter: (params) => {
                  return `${params}%`;
                },
                color: "#000",
                textStyle: {
                  fontSize: "14px",
                },
              },
              splitLine: {
                //网格线
                show: false,
              },
            },
            {
              name: "Overall Success Rate (OSR)",
              nameRotate: -90,
              nameLocation: "middle",
              nameGap: 55,
              nameTextStyle: {
                color: "#000",
                fontSize: "18px",
                // padding: [400, 40, 0, -20],
              },
              //min: 0,
              //max: 100,
              axisLine: {
                show: true, // 不显示坐标轴线
                // symbol: 'arrow',
                lineStyle: {
                  // type: 'dashed',
                  width: 2,
                  color: "#000",
                },
              },
              axisTick: {
                show: true, // 不显示坐标轴刻度线
              },
              axisLabel: {
                formatter: (params) => {
                  return `${params}%`;
                },
                color: "#000",
                textStyle: {
                  fontSize: "14px",
                },
              },
              splitLine: {
                //网格线
                show: false,
              },
            },
          ],
          series: [
            {
              name: "P1SR",
              z: 2,
              type: "bar",
              // data: graph.s_rate1,
              //barWidth: 30,
              // barGap: "-50%",
              itemStyle: {
                color: {
                  type: "linear",
                  x: 1,
                  x2: 0,
                  y: 0,
                  y2: 0,
                  colorStops: [
                    { offset: 0, color: "rgb(101, 162, 210)" },
                    { offset: 0.3, color: "rgb(101, 162, 210)" },
                    { offset: 0.5, color: "rgb(162, 185, 226)" },
                    { offset: 0.75, color: "rgb(101, 162, 210)" },
                    { offset: 1, color: "rgb(101, 162, 210)" },
                  ],
                },
              },
            },
            {
              name: "P2SR",
              z: 2,
              type: "bar",
              // data: graph.s_rate2,
              //barWidth: 30,
              // barGap: "-50%",
              itemStyle: {
                color: {
                  type: "linear",
                  x: 1,
                  x2: 0,
                  y: 0,
                  y2: 0,
                  colorStops: [
                    { offset: 0, color: "rgb(235, 202, 83)" },
                    { offset: 0.3, color: "rgb(235, 202, 83)" },
                    { offset: 0.5, color: "rgb(255, 231, 163)" },
                    { offset: 0.75, color: "rgb(235, 202, 83)" },
                    { offset: 1, color: "rgb(235, 202, 83)" },
                  ],
                },
              },
            },
            {
              name: "P3SR",
              z: 2,
              type: "bar",
              // data: graph.s_rate3,
              //barWidth: 30,
              // barGap: "-50%",
              itemStyle: {
                color: {
                  type: "linear",
                  x: 1,
                  x2: 0,
                  y: 0,
                  y2: 0,
                  colorStops: [
                    { offset: 0, color: "rgb(231, 111, 108)" },
                    { offset: 0.3, color: "rgb(231, 111, 108)" },
                    { offset: 0.5, color: "rgb(240, 166, 162)" },
                    { offset: 0.75, color: "rgb(231, 111, 108)" },
                    { offset: 1, color: "rgb(231, 111, 108)" },
                  ],
                },
              },
            },
            {
              name: "OSR",
              z: 3,
              type: "pictorialBar",
              symbol: point_icon,
              symbolSize: [20, 30],
              symbolPosition: "end",
              yAxisIndex: 1,
              data: graph.DataLOA1["2000-2008"],
              //barWidth: 30,
              // barGap: "-50%",
              itemStyle: {
                color: "rgba(0, 0, 0,.7)",
              },
            },
          ],
        },
        options: [
          {
            title: {
              text: "During 2001-2009",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2001-2009"] },
              { data: graph.DataP2["2001-2009"] },
              { data: graph.DataP3["2001-2009"] },
              { data: graph.DataLOA1["2001-2009"] },
            ],
          },
          {
            title: {
              text: "During 2002-2010",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2002-2010"] },
              { data: graph.DataP2["2002-2010"] },
              { data: graph.DataP3["2002-2010"] },
              { data: graph.DataLOA1["2002-2010"] },
            ],
          },
          {
            title: {
              text: "During 2003-2011",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2003-2011"] },
              { data: graph.DataP2["2003-2011"] },
              { data: graph.DataP3["2003-2011"] },
              { data: graph.DataLOA1["2003-2011"] },
            ],
          },
          {
            title: {
              text: "During 2004-2012",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2004-2012"] },
              { data: graph.DataP2["2004-2012"] },
              { data: graph.DataP3["2004-2012"] },
              { data: graph.DataLOA1["2004-2012"] },
            ],
          },
          {
            title: {
              text: "During 2005-2013",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2005-2013"] },
              { data: graph.DataP2["2005-2013"] },
              { data: graph.DataP3["2005-2013"] },
              { data: graph.DataLOA1["2005-2013"] },
            ],
          },
          {
            title: {
              text: "During 2006-2014",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2006-2014"] },
              { data: graph.DataP2["2006-2014"] },
              { data: graph.DataP3["2006-2014"] },
              { data: graph.DataLOA1["2006-2014"] },
            ],
          },
          {
            title: {
              text: "During 2007-2015",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2007-2015"] },
              { data: graph.DataP2["2007-2015"] },
              { data: graph.DataP3["2007-2015"] },
              { data: graph.DataLOA1["2007-2015"] },
            ],
          },
          {
            title: {
              text: "During 2008-2016",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2008-2016"] },
              { data: graph.DataP2["2008-2016"] },
              { data: graph.DataP3["2008-2016"] },
              { data: graph.DataLOA1["2008-2016"] },
            ],
          },
          {
            title: {
              text: "During 2009-2017",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2009-2017"] },
              { data: graph.DataP2["2009-2017"] },
              { data: graph.DataP3["2009-2017"] },
              { data: graph.DataLOA1["2009-2017"] },
            ],
          },
          {
            title: {
              text: "During 2010-2018",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2010-2018"] },
              { data: graph.DataP2["2010-2018"] },
              { data: graph.DataP3["2010-2018"] },
              { data: graph.DataLOA1["2010-2018"] },
            ],
          },
          {
            title: {
              text: "During 2011-2019",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2011-2019"] },
              { data: graph.DataP2["2011-2019"] },
              { data: graph.DataP3["2011-2019"] },
              { data: graph.DataLOA1["2011-2019"] },
            ],
          },
          {
            title: {
              text: "During 2012-2020",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2012-2020"] },
              { data: graph.DataP2["2012-2020"] },
              { data: graph.DataP3["2012-2020"] },
              { data: graph.DataLOA1["2012-2020"] },
            ],
          },
          {
            title: {
              text: "During 2013-2021",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2013-2021"] },
              { data: graph.DataP2["2013-2021"] },
              { data: graph.DataP3["2013-2021"] },
              { data: graph.DataLOA1["2013-2021"] },
            ],
          },
          {
            title: {
              text: "During 2014-2022",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2014-2022"] },
              { data: graph.DataP2["2014-2022"] },
              { data: graph.DataP3["2014-2022"] },
              { data: graph.DataLOA1["2014-2022"] },
            ],
          },
          {
            title: {
              text: "During 2015-2023",
              left: "center",
            },
            series: [
              { data: graph.DataP1["2015-2023"] },
              { data: graph.DataP2["2015-2023"] },
              { data: graph.DataP3["2015-2023"] },
              { data: graph.DataLOA1["2015-2023"] },
            ],
          },
        ],
      };
      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
  },
  beforeMount() {
    api.getDiseaseDrugtypeClass().then((res) => {
      this.dis_options = res.data.result;
      // console.log(res.data);
    });
  },
  mounted() {
    api.getSuccessRateTrend(this.calMethod).then((res) => {
      this.plotRateTrendData = res.data;
      this.checkRateTrendData = false;
      // console.log(this.dis_value][1]);
      this.plotRateTrend(
        this.plotRateTrendData[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ],
        this.bgColors
      );
    });
    // api.getPhaseDuration().then((res) => {
    //   this.plotPhaseDurationData = res.data;
    //   this.checkPhaseDurationData = false;
    //   this.plotPhaseDuration(
    //     this.plotPhaseDurationData[
    //       this.dis_value[1].replace("All drug modality", "All diseases")
    //     ]
    //   );
    //   // console.log(this.plotPhaseDurationData[this.dis_value]);
    // });
    api.getDrugStatistics1().then((res) => {
      this.plotDrugStatistics1Data = res.data;
      this.checkDrugStatistics1Data = false;
      this.plotDrugStatistics12(
        this.plotDrugStatistics1Data[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ],
        "drug-statistics1",
        "Cumulative number of trials over time"
      );
    });
    api.getDrugStatistics2().then((res) => {
      this.plotDrugStatistics2Data = res.data;
      this.plotDrugStatistics12(
        this.plotDrugStatistics2Data[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ],
        "drug-statistics2",
        "Cumulative number of trials for each time window"
      );
    });
    api.getDrugStatistics3().then((res) => {
      this.plotDrugStatistics3Data = res.data;
      this.plotDrugStatistics3(
        this.plotDrugStatistics3Data[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ]
      );
    });
    api.getDrugStatistics4().then((res) => {
      this.plotDrugStatistics4Data = res.data;
      this.plotDrugStatistics4(
        this.plotDrugStatistics4Data[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ]
      );
    });
    api.getDrugType().then((res) => {
      this.plotDrugTypeData = res.data;
      this.checkDrugTypeData = false;
      this.plotDrugType(
        this.plotDrugTypeData.byDrug[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ],
        "drug-type-byDrug"
      );
      this.plotDrugType(
        this.plotDrugTypeData.byNCT[
          this.dis_value[1].replace("All drug modality", "All diseases")
        ],
        "drug-type-byNCT"
      );
    });
    api.getStatByTime(this.calMethod).then((res) => {
      // this.plotStatByTimeData = res.data.result;
      // console.log(this.plotStatByTimeData);
      this.plotStatByTime(res.data.result);
    });
  },
};
</script>
<style lang="less" scoped>
@select-disease-color: #f1f1f1;
/deep/.div-unit-custom {
  color: #ee7700;
}
.new-font {
  // font-family: "Century Schoolbook Reprise SSi Bold Italic";
  // font-family: "Cortex SSi Bold Italic";
  // font-family: "Deutch Black SSi Black Italic";
  // font-family: "Eras Demi ITC";
  // font-family: "Vremya Italic";
  // font-family: "Yearlind Thin Italic";
  // font-family: "ZabriskieInternational Light Regular";
  font-family: Eras Demi ITC;

  // text-align: center;
  font-size: 40px;
  // margin: -15px 0 -15px 0;
  color: #ee7700;
  font-weight: bold;
}
.new-font-select:hover {
  cursor: pointer;
  text-decoration: underline;
}
/deep/ .el-dialog {
  width: 1000px;
  // height: 600px;
}
/deep/.el-dialog__header {
  padding: 20px;
  background: #455da5;
}
/deep/.dialog-select .el-dialog__header {
  background: #ee7700;
}
/deep/.el-dialog__body {
  color: #2c3e50;
}
/deep/.el-cascader-panel {
  height: 525px !important;
}
/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
/deep/.el-dialog__headerbtn:focus .el-dialog__close,
/deep/.el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff;
}
/deep/.el-scrollbar__wrap {
  overflow: hidden;
  height: 100% !important;
}

/deep/.el-cascader-menu {
  padding-right: 30px;
}

/deep/.train-tree-cascader {
  height: 500px !important;
}

.train-tree-cascader .el-cascader-panel {
  height: 100% !important;
}

.train-tree-cascader .el-cascader-panel .el-cascader-menu__wrap {
  height: 104% !important;
}

/deep/ .el-select:hover {
  .el-input__inner {
    border-color: @select-disease-color;
  }
}

/deep/ .el-select__caret {
  color: #ee7700 !important;
  font-weight: bold !important;
  font-size: 40px !important;
}

/deep/.el-input--suffix {
  .el-input__inner {
    font-family: "ZabriskieInternational Light Regular";
    text-align: center;
    font-size: 40px !important;
    margin: -8px 0 -31px 0;
    color: #ee7700;
    font-weight: bold;
    background: @select-disease-color;
    border: 1px solid @select-disease-color;
    height: 60px;
  }
}

/deep/ .el-select {
  width: 100%;
  margin-top: 10px;

  .el-input__inner:focus {
    border-color: @select-disease-color;
  }

  .el-input.is-focus {
    .el-input__inner {
      border-color: @select-disease-color;
    }
  }
}

.home-interpretation {
  // display: flex;
  // flex-direction: column;
  // margin-top: 30px;
  // box-shadow: 4px 4px 4px 5px #e4e8f5;
  box-shadow: 0 0 2px 0 #7e7c7c;
  border-radius: 5px;
  margin-top: 30px;
  padding: 25px 25px;
  line-height: 1.5;
  background-color: #fafafa;
  text-align: justify;
}

.introduce-text {
  padding: 16px;
  background: #c55a111f;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 7px 6px 11px 0px #cbcaca;
  line-height: 1.8;
}

.plot-block-title {
  /* margin-top: 20px; */
  border: 3px solid #e5e9f5;
  border-bottom: 0;
  color: #6863e5;
  padding: 9px;
  font-weight: bold;
  background: #e5e9f524;
  display: flex;
  justify-content: space-between;
}

.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}

.plot-block {
  /* box-shadow: 1px 3px 8px 4px #c4cce3; */
  /* height: 164px; */
  border: 3px solid #e5e9f5;
  margin: 0px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #e5e9f524;
}

.plot-block-icon {
  border-right: 3px solid #e5e9f5;
  width: 17%;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-around;
  color: #ffffff;
  font-weight: bold;
  margin: auto 0;
}

.plot-block-body {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.plot-block-body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0;
}

.plot-block-wordcloud {
  margin: auto 0;
  width: 20%;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  flex-direction: row;

  border-radius: 30px;
  color: #ffffff;
  font-weight: bold;

  padding: 10px 0;
}

.plot-block-body-row > span {
  margin: auto 0;
  width: 20%;
  display: flex;
  justify-content: space-around;
  border-radius: 30px;
  background: #6e7dd3;
}

.plot-block-body-row > span:hover,
.plot-block-body-row > span:focus {
  text-decoration: none;
  color: #fbfbfb;
  background: #314ad7;
  box-shadow: 0 0 4px 6px #6a88e991;
  text-decoration: none;
}

.plot-block-wordcloud {
  text-decoration: none !important;
}

.plot-block-sunburst {
  text-align: center;
  margin: auto 0;
  width: 20%;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 30px;
  color: #ffffff;
  font-weight: bold;
  background: #fd9d75;
  padding: 10px 0;
}

.plot-block-sunburst:hover,
.plot-block-sunburst:focus {
  text-decoration: none;
  color: #fbfbfb;
  background: #ef5617;
  box-shadow: 0 0 4px 6px #fc84528f;
  text-decoration: none;
}

.tip-title {
  font-size: 20px;
  font-weight: bold;
}

.tip-content {
  font-size: 20px;
}

.big-plot {
  height: 700px;
  width: 100%;
  padding-top: 10px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
}

.half-big-plot {
  height: 450px;
  width: 100%;
  padding-top: 10px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
}

.home-title {
  display: flex;
  text-align: center;
  height: 100px;
  background: #f2f2f2;
  margin-bottom: 15px;
}

.plot-row {
  display: flex;
  justify-content: space-between;
}

.small-plot {
  height: 350px;
  width: 49%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
}

.small-plot {
  height: 350px;
  width: 49%;
  margin-bottom: 10px;
  box-shadow: 1px 1px 4px 5px #e4e8f5;
  border-radius: 6px;
  padding-top: 10px;
}
</style>
