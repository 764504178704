import pathUrl from "./path"
import instance from './request'


const axios = instance;
const api = {
    // upload data =========================================================================
    getTemplateFile() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-template-file");
    },
    // Home 页面API =========================================================================
    getDiseaseDrugtypeClass() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-dis-dt-class");
    },
    getSuccessRateTrend(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-success-rate-trend/" + id);
    },
    getPhaseDuration() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-phase-duration");
    },
    getDrugStatistics1() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-statistics1");
    },
    getDrugStatistics2() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-statistics2");
    }, 
    getDrugStatistics3() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-statistics3");
    },
    getDrugStatistics4() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-statistics4");
    },
    getDrugType() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-type");
    },
    // wordcloud & sunburst ==========================================================================
    getWordCloudData(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-wordcloud/" + id);
    },
    getSunBurstData(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-sunburst/" + id);
    },
    // Advanced analysis 页面API ==========================================================
    getSubdiseaseNum() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-subdisease-num");
    },
    postAdvanced(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-advance-analyse",
        });
    },
    postAdvancedDrugType(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-advance-analyse-drug-type",
        });
    },
    getProgress(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-progress/" + id);
    },
    
    // Drug TImeline 页面API ==========================================================
    getAllDrug() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-all-drug");
    },
    postDrugTimeline(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-drug-timeline",
        });
    },
    postDrugTimelineSuccessRate(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-dtsr",
        });
    },
    // getTimelineProgress(id) {
    //     return axios.get(pathUrl.baseUrl + "/drug_success/api-timeline-progress/" + id);
    // },

    // Contact Part=====================================================================
    postContactUs(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/contactus",
        });
    },

    // Data Part==========================================================================
    // data drug
    getDrugInformation(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-information/" + id);
    },
    // data disease
    getDiseaseInformation(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-disease-information/" + id);
    },




    // Search Part==========================================================================
    // search drug
    postDrugSearch(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-drug-search",
        });
    },
    // search disease
    postDiseaseSearch(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-disease-search",
        });
    },

    // Search Form==========================================================================
    getDiseaseClass() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-disease-class-list");
    },
    getDrugTypeList() {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-type-list");
    },
    getDiseaseList(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-disease-list/" + id);
    },
    getDrugList(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/api-drug-list/" + id);
    },
    // check timeline of drug page and disease page ===========================================
    postCheckTimeline(post_data) {
        return axios({
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            data: post_data, //发送post请求，使用data关键字接收请求参数
            url: pathUrl.baseUrl + "/drug_success/api-check-timeline",
        });
    },
    // Stat ==========================================================================
    getStatDrugStatus() {
        return axios.get(pathUrl.baseUrl + "/drug_success/stat-drug-status");
    },
    getStatDiseaseDrugClass() {
        return axios.get(pathUrl.baseUrl + "/drug_success/stat-disease-drug-class");
    },
    getStatByDisease(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/stat-by-disease/" + id);
    },
    getStatByTime(id) {
        return axios.get(pathUrl.baseUrl + "/drug_success/stat-by-time/" + id);
    },
    
}

export default api;